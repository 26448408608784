/* Card styles */
:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

.Container {
  display: flex;
  overflow-y: scroll;
  max-height: 93vh;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  align-content: flex-start;
  padding-bottom: 220px;
}
.greetingCard {
  overflow: hidden !important;
  flex-wrap: wrap;
  overflow-y: hidden;
  font-weight: 100;
  right: 300px;
  background: #ecefd8;
  min-height: 100px;
  width: 29em;
  margin: 30px auto 10px auto;
  padding: 10px;
  border-radius: 7px;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');

  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.greetingCard h1 {
  font-weight: 200;
  text-align: center;
  color: #535a3b;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.greetingCard p {
  position: relative;
  text-align: center;
  bottom: 20px;
  font-weight: 200;
  font-size: 1.1rem;
  color: #535a3b;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.loadScreen {
  display: flex;
  height: 50%;
  justify-content: space-around;
  justify-content: center;
}
.loadImg {
  position: absolute;
  top: 80px;
  height: 250px;
}

.loadScreen h1 {
  margin: 0;
  font-weight: 200;
  color: #ecefd8;
  text-shadow: 1px 1px 1px #535a3b;
}
.loadScreen p {
  position: relative;
  bottom: 20px;
  font-weight: 200;
  font-size: 1rem;
  color: #ecefd8;
  text-shadow: 1px 1px 1px #535a3b;
}
.menuNav {
  overflow: hidden !important;
  flex-wrap: wrap;
  overflow-y: hidden;
  font-weight: 100;
  right: 300px;
  background: #ecefd8;
  min-height: 30px;
  width: 300px;
  margin: 30px auto 10px auto;
  padding: 5px;
  border-radius: 7px;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

/* media responsive failsafe */

@media (max-width: 600px) {
  .greetingCard {
    flex: 100%;
    max-width: 100%;
    min-height: 130px;
    width: 29em;
  }
}
@media (max-width: 530px) {
  .greetingCard {
    height: 130px;
    width: 26em;
  }
}
@media (max-width: 500px) {
  .greetingCard {
    height: 130px;
    width: 24em;
  }
}
@media (max-width: 450px) {
  .greetingCard {
    height: 130px;
    width: 22em;
  }
}
@media (max-width: 375px) {
  .greetingCard {
    height: 130px;
    width: 20em;
  }
}
@media (max-width: 350px) {
  .greetingCard {
    height: 165px;
    width: 18em;
  }
}
