:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}
/* <nav> */
.Homebar {
  height: var(--nav-size);
  justify-content: center;
  border-radius: 8px;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');
  background-color: #849561;
  padding: 0 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  border-bottom: var(--border);
}

/* <ul> */

.Homebar-nav h1 {
  display: flex;
  justify-content: left;
  right: 1200px;
  bottom: 20px;

  color: #535a3b;

  font-weight: 550;
}

.Homebar-nav {
  max-width: 100%;
  border-radius: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  position: relative;
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #ecefd8;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: #535a3b;
  width: 20px;
  height: 20px;
}
.prompt {
  font-size: larger;
}
