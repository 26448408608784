.birdImg {
  background: #ecefd8;
  height: 380px;
  width: 35em;
  margin: 10px;
  padding: 10px;
  border-radius: 7px;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.birdImg img {
  border-radius: 7px;
  height: 100%;
  width: 100%;
  margin: 0;
  top: 50%;
  left: 50%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.birdCard {
  display: flexbox;
  font-weight: 600;
  text-overflow: ellipsis !important;
  color: #535a3b;
  top: 10px;
  background: #ecefd8;
  min-height: 130px;
  width: 35em;
  margin: 10px;
  padding: 10px;
  border-radius: 7px;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
  box-shadow: 5px 5px 5px rgba(30, 19, 19, 0.3);
}

.birdCard h1 {
  font-size: 170%;
  margin: 0;

  font-weight: 500;
  color: #535a3b;
}

.birdCard p {
  bottom: 100px;
  margin: 0;
  font-weight: 600;
  color: #535a3b;
}

.birdCard h5 {
  font-size: 100%;
  margin: 0;
  font-weight: 600;
  color: #535a3b;
}

.birdCard a {
  text-decoration: underline;
}

.birdCard a:hover {
  color: #4d96ff;
}

/* mobile responsive failsafe */

@media (max-width: 600px) {
  .birdCard {
    max-width: 100%;
    min-height: 130px;
    width: 30em;
  }
  .birdImg {
    max-width: 100%;
    width: 30em;
  }
}
@media (max-width: 530px) {
  .birdCard {
    width: 26em;
  }
  .birdImg {
    width: 26em;
    height: 330px;
  }
}
@media (max-width: 460px) {
  .birdCard {
    width: 22em;
  }
  .birdImg {
    width: 22em;
    height: 300px;
  }
}

@media (max-width: 400px) {
  .birdCard {
    width: 20em;
  }
  .birdImg {
    width: 20em;
    height: 270px;
  }
}

@media (max-width: 360px) {
  .birdCard {
    width: 18em;
  }
  .birdImg {
    width: 18em;
    height: 230px;
  }
}
