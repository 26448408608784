/*Dropdown Menu */

.dropdown {
  position: absolute;
  height: 300px;
  overflow-y: scroll !important;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: #849561;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');
  border: var(--border);
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  font-weight: 700;
  display: flex;
  color: #535a3b;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-left {
  margin-right: 0.5rem;
}

.menu-item .icon-left:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #cedcc3;
}

.icon-right {
  margin-left: auto;
  position: relative;
  top: 5px;
}
.icon-left {
  position: relative;
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #849561;
  background-image: url('https://www.transparenttextures.com/patterns/soft-wallpaper.png');
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
  height: 550px !important;
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
