body {
  margin: 0;
  overflow-y: hidden;
  background: #eff7d3;
  background-image: url(./Img/IMG_0528.JPG);
  font-family: 'Hind', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Hind', sans-serif;
}

:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #393939;
  text-decoration: none;
}

footer {
  text-align: center;
  color: whitesmoke;
  position: relative;
  top: 80vh;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: #cedcc3;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #646464;
  height: 30%;
  outline: 2px solid #646464;
  outline-offset: -2px;
  border: 0.7px solid #646464;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #eff7d3;
}
